import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Header from "./components/Header";
import Main from "./views/Main";
import Footer from"./components/Footer";
import RemoveFocusWhenNotTab from "./components/RemoveFocusNotTab";
import Alert from './components/Alert';
import AutoLogout from "./components/AutoLogout";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {selectDisplayMode, selectSWInitState, selectSWRegistration, selectSWUpdateState} from "./store";
import {DISPLAY_MODE_DARK, LOCAL_TAG_QUERY_PARAMS, LOCAL_TAG_REMEMBER_ME} from "./constains/types";
import {setQueryParams} from "./reducers/appStateReducer";
import {UseLocalStorage} from "./services/UseLocalStorage";

const App = ()=> {

    const displayMode = useSelector(selectDisplayMode);
    const dispatch = useDispatch();
    const [newSearch] = useState(window.location.search);
    const [cacheSearch, setCacheSearch] = UseLocalStorage(LOCAL_TAG_QUERY_PARAMS, window.location.search);

    useEffect( () => {
        const search = newSearch !== '' ? newSearch : cacheSearch
        dispatch(setQueryParams(search));
        setCacheSearch(search);


        window.bridge || (window.bridge = function() {
            var callbacks = [],
                callbackID = 0,
                registerHandlers = [];
            return document.addEventListener("PacificDidReceiveNativeCallback", function(e) {
                if (e.detail) {
                    var detail = e.detail,
                        id = isNaN(parseInt(detail.id)) ? -1 : parseInt(detail.id);
                    if (- 1 != id && callbacks[id] && callbacks[id](detail.parameters, detail.error))
                        delete callbacks[id]
                }
            }, !1), document.addEventListener("PacificDidReceiveNativeBroadcast", function(e) {
                if (e.detail) {
                    var detail = e.detail,
                        name = detail.name;
                    if (void 0 !== name && registerHandlers[name]) {
                        var namedListeners = registerHandlers[name];
                        if (namedListeners instanceof Array) {
                            var parameters = detail.parameters;
                            namedListeners.forEach(function(handler) {
                                handler(parameters)
                            })
                        }
                    }
                }
            }, !1), {
                post: function(action, parameters, callback, print) {
                    var id = callbackID++;
                    callbacks[id] = callback;
                    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.pacific)
                        window.webkit.messageHandlers.pacific.postMessage({
                        action: action,
                        parameters: parameters,
                        callback: id,
                        print: print || 0
                    })
                },
                on: function(name, callback) {
                    var namedListeners = registerHandlers[name];
                    return registerHandlers[name] || (registerHandlers[name] = []), registerHandlers[name].push(callback),
                        function() {
                            registerHandlers[name][namedListeners.indexOf(callback)] = null
                        }
                },
                off: function(name) {
                    delete registerHandlers[name]
                }
            }
        }())
    }, []);

    const appLoader = () =>{
        let appBody = [];

          appBody = <div className={`app d-flex flex-column m-auto ${displayMode == DISPLAY_MODE_DARK ? "dark" : ""}`}>

                    {RemoveFocusWhenNotTab()}

                    <Header/>
                    <Main />

                    <Footer/>
                    <ToastContainer />
            </div>;


        return appBody;
    }

    return(
        <>{appLoader()}</>
    )

}
export default App;
