import React from "react";
import Logo from "../../images/logo-upstream.png";
import Navigation from "../views/Navigation";

import {useSelector} from "react-redux";
import {selectLoginState, selectQueryParams} from "../store";
import {useRouteMatch} from "react-router-dom";
import {backToSetting} from "../utilitis/helper";

const Header = () => {
    //const [loginState,setLoginState] = useLogin();
    const isLogin = useSelector(selectLoginState);
    const query = new URLSearchParams(useSelector(selectQueryParams));
    const embeddedInAppLayer = query.get('isEmbeddedInAppLayer') === 'true';

    const onBackToSettingClick = e => {
        if (embeddedInAppLayer) {
            backToSetting();
        }
    }

    const match = useRouteMatch({
        path: "/deliveries/:guid",
        exact: true,
        strict: true,
        sensitive: true
    });

    const LoggedInHeader = ()=>{
        return(
            <header className={`header-app login ${match ? 'hide' : 'active'}`}>
                <div className="mw-768 m-auto container">
                    <Navigation/>
                </div>
            </header>
        );
    }

    const NonLoggedInHeader = () =>{

        return(
            <header className={"header-app"}>
                <div className="mw-768 m-auto container">
                    <img src={Logo} alt="Logo" className="w-auto mb-3" onClick={onBackToSettingClick}/>
                </div>
                <div className={"page-header"}>App version: {process.env.REACT_APP_VERSION}<small>({process.env.REACT_APP_BUILD})</small></div>
            </header>
        );
    }

    return(isLogin ? LoggedInHeader():NonLoggedInHeader());

};

export default Header;
