import {useTranslation} from "react-i18next";
import {useDeliveryForm} from "../../services/DeliveryFormContext";
import {useDepServices} from "../../services/DepServicesContext";
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import StaticMapViewer from "./StaticMapViewer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingCircle from "../../components/LoadingCircle";
import ReturnJobBtn from "./buttons/ReturnJobBtn";
import DeliveryInfoPanel from "./DeliveryInfoPanel";
import DeliveryForm from "./DeliveryForm";
import {push} from "connected-react-router";
import {Button, Col, Row} from "react-bootstrap";


const GroupDelivery = ({group}) => {

    const {t, i18n} = useTranslation();
    const {formValues} = useDeliveryForm();
    const {deliveriesService} = useDepServices();
    const dispatch = useDispatch();

    const backToDeliveriesHandler = e => {
        dispatch(push('/deliveries/'));
        e.preventDefault();
    }


    const handleSubmit = async (status) => {
        await deliveriesService.saveDelivery(status, formValues);
        dispatch(push('/deliveries/'));
    }

    const renderDetailsList = () => {


        const renderStatic = () => (group["static_map"] !== "" || group["static_map"] !== null) ? <StaticMapViewer job={group}/> : <></>;

        return (
            <>
                <Row className="m-0">
                    <Col xs={12} className="mt-3">
                        <div className={"btn-container"}>
                            <Button size={"lg"} variant={"primary"} onClick={(e) => backToDeliveriesHandler(e)}>
                                <FontAwesomeIcon icon={["fas", "arrow-left"]}/>
                                <span className={"btn-label"}>Back To Deliveries</span>
                            </Button>
                            {renderStatic()}
                        </div>

                        <DeliveryInfoPanel job={group} />
                        <DeliveryForm handleDeliverySubmit={handleSubmit}/>
                    </Col>
                </Row>
            </>
        );
    }


    return (renderDetailsList());
}

export default GroupDelivery;
