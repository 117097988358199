import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadingCircle from "../../components/LoadingCircle";
import {push} from "connected-react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PdfViewer from "../../components/PdfViewer";
import ReturnJobBtn from "../../views/Deliveries/buttons/ReturnJobBtn";
import StaticMapViewer from "./StaticMapViewer";
import {useDispatch} from "react-redux";
import DeliveryInfoPanel from "./DeliveryInfoPanel";
import PDFBtn from "../../views/Deliveries/buttons/PDFBtn";
import DeliveryForm from "./DeliveryForm";
import {useDeliveryForm} from "../../services/DeliveryFormContext";
import {useDepServices} from "../../services/DepServicesContext";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {setJobStatus} from "../../constains/deliveryFormActions";


const SingleJobDelivery = ({job}) => {

    const {t, i18n} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPDFForm, setShowPDFForm] = useState(false);
    const [jobConfirmation, setJobConfirmation] = useState(false);
    const {formValues} = useDeliveryForm();
    const {deliveriesService} = useDepServices();
    const [showReturnJobConfirm, setShowReturnJobConfirm] = useState(false);
    const dispatch = useDispatch();

    const renderDetailsList = () => {
        /** ## check if dbArray state is empty and repopulate data from indexedDB table **/


        const renderPDF = () => (job["job_pdf"] !== "" || job["job_pdf"] !== null) ?<PdfViewer jobPDF={job.job_pdf} jobExtras={formValues.job_extras}/>: <></>;
        const renderPDFBtn = showPDFForm => (job["job_extras"].length !== 0) ? <PDFBtn showPDFForm={showPDFForm} handler={(e)=>pdfBtnHandler(e)}/>: <></>;
        const renderStatic = () => (job["static_map"] !== "" || job["static_map"] !== null) ? <StaticMapViewer job={job}/>: <></>;

        const backToDeliveriesHandler = async (e) => {
            e.preventDefault();
            await deliveriesService.saveDelivery(formValues.job_status, formValues);
            dispatch(push('/deliveries/'));
        }

        const pdfBtnHandler = e => {
            e.preventDefault();
            setShowPDFForm(! showPDFForm);
        }

        const returnJobHandler = async () => {
            await deliveriesService.saveDelivery(0, formValues);
            dispatch(setJobStatus(0));
            dispatch(push('/deliveries/'));
        }

        const showJobConfirmation = () => setShowReturnJobConfirm(true);
        const closeJobConfirmation = () => setShowReturnJobConfirm(false);

         const handleSubmit = async (status) => {
             await deliveriesService.saveDelivery(status, formValues);
             if (status !== 2) {
                 dispatch(push('/deliveries/'));
             }
         }

         return (
             <>
             <Row className="m-0">
                 {/*<div className={jobConfirmation === true ? "job-overlay active" : "job-overlay"}>*/}
                 {/*    <div className={jobConfirmation === true ? "job-return message active" : "job-return message"}>*/}
                 {/*        <div className="message-container">*/}
                 {/*            <div className="message-container-item info">*/}
                 {/*                <div>*/}
                 {/*                    <h5>Return Job?</h5>*/}
                 {/*                </div>*/}
                 {/*                <div className="d-flex flex-row">*/}
                 {/*                    <button className="btn-confirm" onClick={returnJobHandler} disabled={isSubmitting}>*/}
                 {/*                        <div>{isSubmitting ? "" : <FontAwesomeIcon icon={["fas", "check"]}/>}*/}
                 {/*                            <span>{isSubmitting ? "" : "Yes"}</span></div>*/}
                 {/*                        <LoadingCircle isSubmitting={isSubmitting}/>*/}
                 {/*                    </button>*/}
                 {/*                    <button className="btn-cross" onClick={jobConfirmationToggle}><FontAwesomeIcon*/}
                 {/*                        icon={["fas", "times"]}/>No*/}
                 {/*                    </button>*/}
                 {/*                </div>*/}
                 {/*            </div>*/}
                 {/*        </div>*/}
                 {/*    </div>*/}
                 {/*</div>*/}

                 <Col xs={12} className="mt-3">
                     {/*<Row>*/}
                     {/*    <Col xs={4} className={"text-md-center text-lg-start"}>*/}
                     {/*        <Button size={"lg"} variant={"primary"} onClick={(e) => backToDeliveriesHandler(e)}>*/}
                     {/*            <FontAwesomeIcon icon={["fas", "arrow-left"]}/>*/}
                     {/*            <span className={"ms-2"}>Back To Deliveries</span>*/}
                     {/*        </Button>*/}
                     {/*    </Col>*/}
                     {/*    {renderStatic()}*/}
                     {/*    {renderPDF()}*/}
                     {/*    <Col xs={3} className={"text-center text-lg-end"}>*/}
                     {/*        <Button variant={"primary"} onClick={showJobConfirmation} size={"lg"}>*/}
                     {/*           <FontAwesomeIcon icon={["fas", "undo-alt"]}/>*/}
                     {/*           <span className={"ms-1"}>Return Job</span>*/}
                     {/*       </Button>*/}
                     {/*    </Col>*/}
                     {/*</Row>*/}
                     <div className={"btn-container"}>
                         <Button size={"lg"} variant={"primary"} onClick={(e) => backToDeliveriesHandler(e)}>
                             <FontAwesomeIcon icon={["fas", "arrow-left"]}/>
                             <span className={"btn-label"}>Back To Deliveries</span>
                         </Button>
                         {renderStatic()}
                         {renderPDF()}
                         {formValues.job_status === 2 ? <Button variant={"primary"} onClick={showJobConfirmation} size={"lg"}>
                            <FontAwesomeIcon icon={["fas", "undo-alt"]}/>
                            <span className={"btn-label"}>Return Job</span>
                        </Button> : <></>}
                     </div>
                 </Col>
                 <DeliveryInfoPanel job={job} />
                 {renderPDFBtn(showPDFForm)}
                 <DeliveryForm showPDFForm={showPDFForm} handleDeliverySubmit={handleSubmit}/>

             </Row>
             <Modal show={showReturnJobConfirm} onHide={closeJobConfirmation} centered>
                 <Modal.Body><h5>{t("ui:deliveries.message_return_job")}</h5></Modal.Body>
                 <Modal.Footer >
                     <Button variant="secondary" onClick={closeJobConfirmation}>
                         <FontAwesomeIcon icon={["fas", "times"]}/> {t("ui:generic.button.no")}
                     </Button>
                     <Button variant="primary" onClick={returnJobHandler}>
                         <FontAwesomeIcon icon={["fas", "check"]}/> {t("ui:generic.button.yes")}
                     </Button>
                 </Modal.Footer>
             </Modal>
             </>
         );
    };

    return (renderDetailsList());

};

export default SingleJobDelivery;
