import {
    SET_DELIVERY_TYPE,
    SET_NEWJOB_COUNT,
    SET_LOCALJOBS_COUNT,
    UPDATE_PRECHECKLIST,
    UPDATE_USERQUEUES,
    RESET_DELIVERY_STATUS
} from "../constains/appStateActions";

import {MSOGAPIClient} from "../utilitis/MSOGAPIClient";
import {buildMiddlewareActionFromAPI, createAsyncFetchAction} from "../utilitis/helper";
import {DELIVERY_TYPE_SINGLE} from "../constains/types";

export const InitialDeliveryState = {
    newJobCount: 0,
    ongoingJobCount: 0,
    completedJobCount: 0,
    deliveryType: DELIVERY_TYPE_SINGLE,
    precheckList: {},
    userqueues: {},
};


export function deliveryStateReducer(state = {}, action) {

    switch (action.type) {
        case SET_NEWJOB_COUNT:
            return {...state,
                newJobCount: action.payload,
            };
        case SET_LOCALJOBS_COUNT:
            return {...state,
                ongoingJobCount: action.payload.ongoing,
                completedJobCount: action.payload.completed
            };
        case SET_DELIVERY_TYPE:
            return {...state,
                deliveryType: action.payload,
            };
        case UPDATE_PRECHECKLIST:
            return {...state,
                precheckList: { data: [...action.payload.value], guid: action.payload.guid , update_cnt: (state.precheckList.update_cnt) ? state.precheckList.update_cnt + 1 : 1},
            };
        case UPDATE_USERQUEUES:
            return {...state,
                userqueues: { data: [...action.payload], update_cnt: (state.userqueues.update_cnt) ? state.userqueues.update_cnt + 1 : 1},
            }
        case RESET_DELIVERY_STATUS:
            return {... InitialDeliveryState};
    }
    return state;
}

export function setNewJobCount(value) {
    return {
        type:SET_NEWJOB_COUNT,
        payload:value
    };
};



export function createFetchPrecheckList(sessionService, client, callback = undefined) {
    return createAsyncFetchAction(UPDATE_PRECHECKLIST, sessionService, client, client.getPrecheckList, callback);
}


export const setLocalJobsCount = (ongoing, completed) => { return {type:SET_LOCALJOBS_COUNT, payload: {ongoing: ongoing, completed: completed}};};
export const setDeliveryType = value => { return {type:SET_DELIVERY_TYPE, payload:value};};
export const setPrecheckList = (guid, value) => { return {type:UPDATE_PRECHECKLIST, payload: { value:value, guid: guid}};};
export const setUserQueues = value => { return {type:UPDATE_USERQUEUES, payload:value};};
export const resetDeliveryStatus = () => {return {type: RESET_DELIVERY_STATUS}; };
