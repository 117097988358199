import React, {useEffect, useRef} from "react";
import {globalToLocalFieldGrid} from "../../utilitis/helper";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const DropDown = ({fieldname, description = "", fieldvalue = "", fieldlogic = "", fieldid = "", required = '0', editable = true,  fieldcss = {}, handler = () => {}, register = () => {}, errors = () => {}, setValue = () => {}}) =>{

    let dropdownValues = JSON.parse(fieldlogic) ?? [];
    const [labelCol, fieldCol, labelColTablet, fieldColTablet] = globalToLocalFieldGrid(fieldcss);
    const {t} = useTranslation();
    const displayname = description && description !== '' ? description : fieldname;
    const inputRef = useRef();


    useEffect(() => {
        inputRef.current.dispatchEvent(new Event('change', { bubbles: true}));
    }, []);

    const optionTags = ()=>{
        let tagList = [];
        dropdownValues.map((option,index) => {
            tagList.push(<option key={index} id={option.guid} value={option.value}>{option.display ? option.display : option.value}</option>);
            return "";
        })
        return tagList;
    }

    const onValueChange = (e) => {
        setValue(fieldid,e.target.value);
        handler(e)
    }

    return (
        <Form.Group className="row">
            <div className={`col-${labelCol} col-md-${labelColTablet} text-left`}>
                <label htmlFor={fieldid} className="ps-3">{displayname}{required == 1 ? ' *' : ''}</label>
            </div>
            <div className={`col-${fieldCol} col-md-${fieldColTablet}`}>
                <select className={`form-select form-control ${errors && errors[fieldid] ? "is-invalid" : ""}`} id={fieldid}
                        data-guid={fieldid}  {...register(fieldid, {required: required !== 0})} defaultValue={fieldvalue} onChange={onValueChange} ref={inputRef} disabled={(editable) ? "" : "disabled"}>
                    {optionTags()}
                </select>
                <div className="invalid-feedback">{t("ui:generic.dropdown.invalid_message", {name: fieldname})}</div>
            </div>
        </Form.Group>
    );
}

export default DropDown;
