import {MSOGAPIClient, MSOGAPIError} from "../utilitis/MSOGAPIClient";
import React, {createContext, useCallback, useContext, useMemo} from "react";
import {fetchLocalStorage, setLocalStorage, updateApplayerTracking} from "../utilitis/helper";
import {
    setLoginStatus,
    setQueueSelectedStatus,
    setVehicleCheckedStatus
} from "../reducers/appStateReducer";
import {setSyncingStatus} from "../reducers/runtimeStateReducer";
import {Mutex} from "async-mutex";
import MSOGDB, {UNKNOWN_USER_GUID} from "./MSOGDB";
import {resetDeliveryStatus, setNewJobCount, setUserQueues} from "../reducers/deliveryStateReducer";

const SHA512 = require("crypto-js/sha512");
const moment = require('moment');

const LOCAL_TAG_VEHICLE_CHCKED_TIME = "vehicle_checked_time";
const LOCAL_TAG_SESSION = "session";
const LOCAL_TAG_USERNAME = "username";
const LOCAL_TAG_SELECTED_QUEUE = "selected_queue";
const LOCAL_TAG_TRACKING_STATE = "tracking_state";

export class SessionService {


    constructor(initStore, initClient = null) {
        this.store = initStore;
        this.client = initClient ?? new MSOGAPIClient(initStore);
        this.mutex = new Mutex();
        this.session = fetchLocalStorage(LOCAL_TAG_SESSION, undefined);
        this.username =  fetchLocalStorage(LOCAL_TAG_USERNAME, undefined);
        this.selectedQueue =  fetchLocalStorage(LOCAL_TAG_SELECTED_QUEUE, undefined);
        this.vehicleCheckedTime = fetchLocalStorage(LOCAL_TAG_VEHICLE_CHCKED_TIME, undefined);
        this.trackingState = fetchLocalStorage(LOCAL_TAG_TRACKING_STATE, {trackingQueueGuid: undefined, trackingSession: undefined})
        this.db = MSOGDB();
    };

    dbDriverLog = async () => {
        if (! this.db.isOpen()) {
            await this.db.open()
        }
        return this.db;
    }

    setLoginSession = newSession => {
        newSession.login_time = moment().unix();
        newSession.token_id = (newSession.access_token) ? SHA512(newSession.access_token).toString() : "";
        newSession = (this.session === undefined) ? newSession : {...newSession, user_id: this.session.user_id};
        this.session = setLocalStorage(LOCAL_TAG_SESSION, newSession);
    };

    clearSession = (isCacheEnable = false) => {
        this.updateTracking("off");
        this.username =  (! isCacheEnable) ? setLocalStorage(LOCAL_TAG_USERNAME, undefined ) : this.username;
        this.session = setLocalStorage(LOCAL_TAG_SESSION, undefined);
        this.setDeliveredQueue(undefined);
        this.store.dispatch(resetDeliveryStatus());
        this.store.dispatch(setLoginStatus(false));
    };

    setDeliveredQueue = queue => {
        this.selectedQueue = setLocalStorage(LOCAL_TAG_SELECTED_QUEUE, queue);
        this.vehicleCheckedTime = setLocalStorage(LOCAL_TAG_VEHICLE_CHCKED_TIME, undefined);
        this.store.dispatch(setQueueSelectedStatus(queue !== undefined));
        this.store.dispatch(setNewJobCount(0));
        this.store.dispatch(setSyncingStatus( false));
        this.isVehicleCheckDoneImpl();
    };

    isVehicleCheckDoneImpl = () => {
        const result = this.selectedQueue !== undefined && (this.selectedQueue.require_vehicle_check !== 1 || this.vehicleCheckedTime !== undefined && moment().format("MM/DD/YYYY") === moment.unix(this.vehicleCheckedTime).format("MM/DD/YYYY"));
        this.store.dispatch(setVehicleCheckedStatus(result));
        return result;
    };

    async postPasswordGrant(username, password, domain) {

        let result = await this.client.postPasswordGrant(username, password, domain);

        if (! (result instanceof MSOGAPIError)) {
            this.setLoginSession(result);
            this.username = setLocalStorage(LOCAL_TAG_USERNAME, username);
            this.store.dispatch(setLoginStatus(true));
            return true;
        } else {
            return result;
        }
    };

    isLogin() {
        return this.session !== undefined;
    };

    isVehicleCheckDone() {
        return this.isVehicleCheckDoneImpl();
    };

    async getAccessToken() {
        return await this.mutex.runExclusive( async () => {
            if (this.session !== undefined && moment().unix() > this.session.expires_in + this.session.login_time) {
                const result = await this.client.refreshToken(this.session.refresh_token);

                if (result instanceof MSOGAPIError) {
                    if (result.code !== 408) {
                        this.clearSession();
                        return result;
                    }
                } else {
                    this.setLoginSession(result);
                    return result.access_token;
                }
            }
            return (this.session !== undefined) ? this.session.access_token : false;

        });
    };

    getTrackingPromptState() {
        return this.trackingState.trackingSession === undefined || this.trackingState.trackingQueueGuid === undefined || this.session === undefined || this.selectedQueue === undefined ? 0 : (this.trackingState.trackingQueueGuid !== this.selectedQueue.guid || this.trackingState.trackingSession.token_id !== this.session.token_id ? 1 : -1);
    };

    async updateTracking(status) {

        const query = new URLSearchParams(this.store.getState().appStateReducer.queryParams),
            settings = this.store.getState().appStateReducer.appSettings

        if (settings.enable_applayer_tracking) {

            const accessToken = status === "on" ? await this.getAccessToken() : "",
                userGuid = this.getUserGUID(),
                interval = settings.applayer_tracking_interval ?? 1,
                domain = query.get('domain') ?? window.location.hostname.replace('app.', '')

            if (query.get('isEmbeddedInAppLayer') === 'true') {
                updateApplayerTracking({
                    queueGUID: this.selectedQueue.guid,
                    accessToken: accessToken,
                    userGUID: userGuid,
                    interval: interval,
                    domain: domain,
                    status: status
                })
            } else if (settings.applayer_launch_protocol === "universal") {
                window.open(`https://msog.udocscloud.com.au/test/?status=${status}&queueGUID=${this.selectedQueue.guid}&accessToken=${accessToken}&userGUID=${userGuid}&interval=${interval}&domain=${domain}`, '_top');
            } else {
                window.open(`ubr:tracking?status=${status}&queueGUID=${this.selectedQueue.guid}&accessToken=${accessToken}&userGUID=${userGuid}&interval=${interval}&domain=${domain}`);
            }
        }
        this.trackingState = setLocalStorage(LOCAL_TAG_TRACKING_STATE, {trackingQueueGuid: this.selectedQueue.guid, trackingSession: this.session});
    };

    getUserName() {
        return this.username ?? "";
    };

    getDeliveredQueue() {
        return this.selectedQueue ?? false;
    };

    getUserGUID() {
        return (this.session !== undefined) ? this.session.user_id : UNKNOWN_USER_GUID;
    };

    async saveLocation(lat, long) {
        const trackingTime = moment().unix(),
            location = {
                user_guid: this.getUserGUID(),
                job_queue_guid: this.getDeliveredQueue().guid,
                tracking_time: trackingTime,
                lat: lat,
                long: long
            },
            connection = await this.dbDriverLog();

        await connection.locations.add(location);
    };


    async getLocations() {
        const connection = await this.dbDriverLog(),
            locations = connection.locations.toArray();

        return locations;
    };

    async removeLocations(locations) {
        const connection = await this.dbDriverLog();
        for (const loc of locations) {
            await connection.locations.where({user_guid: loc.user_guid, job_queue_guid: loc.job_queue_guid, tracking_time: loc.tracking_time}).delete();
        }
    }

    async saveDriverLog (listGuid, status, value) {
        const checked_at = moment().unix(),
            connection = await this.dbDriverLog(),
            driver_log = {
                list_guid: listGuid,
                user_id: this.getUserGUID(),
                queue_id: this.getDeliveredQueue() ? this.getDeliveredQueue().guid : "",
                status: status,
                vehicle_check: value.reduce((previousValue, value) => { return {...previousValue, [value.name]: {type: value.type, value: value.value}}; }, {}),
                checked_at: checked_at,
                token_id: this.session.token_id,
            };

        await connection.driver_logs.add(driver_log);
        if (status === 1) {
            this.vehicleCheckedTime = setLocalStorage(LOCAL_TAG_VEHICLE_CHCKED_TIME, checked_at);
            this.store.dispatch(setVehicleCheckedStatus(true));
        }
    };


    async getDriverLogs() {
        const connection = await this.dbDriverLog(),
            logs = connection.driver_logs.toArray();

        return logs;
    };

    async removeDriverLogs(logs) {
        const connection = await this.dbDriverLog();
        for (const log of logs) {
            await connection.driver_logs.where({user_id: log.user_id, queue_id: log.queue_id, checked_at: log.checked_at}).delete();
        }
    }
}

const SessionServiceContext = createContext({});

export const useSessionService = () => useContext(SessionServiceContext);

export const SessionServiceProvider = ({children, service}) => {

    return (
        <SessionServiceContext.Provider value={service}>
            {children}
        </SessionServiceContext.Provider>
    )
}
