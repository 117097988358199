import Dexie from "dexie";

export const UNKNOWN_USER_GUID = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"

const MSOGDB = (db)=> {
    db = new Dexie("MSOGDatabase");

    db.version(1).stores({
        jobs: 'guid, status',
        job_extras: 'guid, jobguid',
        line_items: 'guid, jobguid',
        driver_logs: '[user_id+queue_id+checked_at]'
    });

    db.version(2).stores({
        jobs: 'guid, status',
        job_extras: 'guid, jobguid',
        line_items: 'guid, jobguid',
        driver_logs: '[user_id+queue_id+checked_at]',
        locations: '[user_guid+job_queue_guid+tracking_time]'
    });

    db.version(3).stores({
        jobs: 'guid, status, user_guid',
        job_extras: 'guid, jobguid',
        line_items: 'guid, jobguid',
        driver_logs: '[user_id+queue_id+checked_at]',
        locations: '[user_guid+job_queue_guid+tracking_time]'
    }).upgrade(trans => {
         return trans.jobs.toCollection().modify (job => {
            job.user_guid = UNKNOWN_USER_GUID;
        });
    });
    return db;
}

export default MSOGDB;
