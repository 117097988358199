import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import Select from "react-select";
import UseDeliveriesInputHandler from "../../components/UseDeliveriesInputHandler";
import {useDeliveryForm} from "../../services/DeliveryFormContext";
import {useTranslation} from "react-i18next";
import {Col} from "react-bootstrap";

const DeliveryInfoPanel = ({job}) => {

    const {t, i18n} = useTranslation();
    const {_, dispatch} = useDeliveryForm();
    const [selectedOptions, setSelectedOptions] = useState((job.guids ? []:[job.guid]));
    const options = (job.guids) ? job.guids.map(element => {return { value: element.jobguid, label: element.doc_number}}) : [];


    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            color: 'black',
        }),
    }

    const handleChange = (vals) => {
        setSelectedOptions(vals);
        dispatch({type: 'update_selected_jobguids', payload: vals.map(element => element.value)});
    }

    const renderDocDetails = () => {
        let docDetails = []
        if (job["doc_type"] !== null && job["doc_type"] !== "") {
            docDetails.push(<div key={"doc_type" + job.guid}><span><FontAwesomeIcon icon={["fas", "info-circle"]}/> Document Type:</span>
                <span>{job["doc_type"]}</span></div>);
        }

        if (job["doc_number"] !== null && job["doc_number"] !== "") {
            docDetails.push(<div key={"doc_number" + job.guid}><span><FontAwesomeIcon icon={["fas", "file"]}/> Doc Number: </span>
                <span>{job["doc_number"]}</span></div>);
        }

        if (job.guids) {
            docDetails.push(<div key={`selected_guids${job.guid}`}><Select options={options} isMulti name={`selected_guids${job.guid}`} value={selectedOptions} onChange={handleChange} styles={customStyles} placeholder={t('ui:group_delivery.jobselect_placeholder')}/></div>)
        }
        return docDetails;
    }

    const renderJobUserDetails = () => {
        let jobUserDetails = []
        if (job["customer_name"] !== null && job["customer_name"] !== "") {
            jobUserDetails.push(<div key={"customer_name" + job.guid}><span><FontAwesomeIcon
                icon={["fas", "user"]}/></span><span>{job["customer_name"]}</span></div>);
        }
        if (job["mobile"] !== null && job["mobile"] !== "") {
            jobUserDetails.push(<div key={"mobile" + job.guid}><span><FontAwesomeIcon icon={["fas", "phone-alt"]}/></span><span>{job["mobile"]}</span>
            </div>);
        }


        if (job["full_address"] !== null && job["full_address"] !== "") {
            jobUserDetails.push(<div key={"full_address" + job.guid}><span><FontAwesomeIcon
                icon={["fas", "map-marked"]}/></span><span>{job["full_address"]}</span></div>);
        }

        return jobUserDetails;
    }
    
    
    return (<Col xs={12} className="job-details">
                <div className="doc-details">
                    {renderDocDetails()}
                </div>
                <div className="user-details bg-white">
                    {renderJobUserDetails()}
                </div>
            </Col>);
}

export default DeliveryInfoPanel;
